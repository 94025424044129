export const titleValidations = {
  required: "This field is required",
  minLength: {
    value: 5,
    message: "5 characters minimum"
  },
  maxLength: {
    value: 300,
    message: "Max 300 characters"
  }
};

export const descriptionValidations = {
  required: "This field is required",
  minLength: {
    value: 10,
    message: "10 characters minimum"
  },
  maxLength: {
    value: 10000,
    message: "Max 10,000 characters"
  }
};
