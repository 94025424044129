import React from "react";
import {
  DialogRoot,
  SecondaryButton,
  FormDivider
} from "../../../forms/styles";
import { LoginForm } from "./LoginForm";
import { ExternalAuthProviders } from "../externalAuth/ExternalAuthProviders";

export const Login = ({ isOpen, handleClose, switchForm }) => {
  return (
    <DialogRoot data-cy="login-dialog" open={isOpen} onClose={handleClose}>
      <ExternalAuthProviders />

      <FormDivider />

      <LoginForm />

      <FormDivider />

      <SecondaryButton onClick={switchForm}>Register Instead?</SecondaryButton>
    </DialogRoot>
  );
};
