import React from "react";
import { Loading } from "../layout/Loading";
import { useParams } from "react-router-dom";
import { PageContent } from "../styles/layout";
import { ProjectListItem } from "./ProjectListItem";
import { useProject } from "../../data/firebase/hooks/useProject";
import { ItemNotFoundPage } from "../errorPages/ItemNotFoundPage";

const Project = () => {
  const { id } = useParams();
  const response = useProject(id);
  if (!response) {
    return <Loading />;
  }
  if (response.error) {
    return <ItemNotFoundPage />;
  }
  return (
    <PageContent>
      <ProjectListItem {...response.data} fullPost={true} />
    </PageContent>
  );
};

export { Project };
