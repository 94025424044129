/*
https://stackoverflow.com/questions/4348590/how-can-i-make-git-ignore-future-revisions-to-a-file

git update-index --skip-worktree default_values.txt
That will ignore changes to that file, both local and upstream, until you decide to allow them again with:

git update-index --no-skip-worktree default_values.txt
You can get a list of files that are marked skipped with:

git ls-files -v . | grep ^S
Note that unlike --skip-worktree, the --assume-unchanged status will get lost once an upstream change is pulled.

https://stackoverflow.com/questions/4348590/how-can-i-make-git-ignore-future-revisions-to-a-file
*/

export const prod = {
  apiKey: "AIzaSyBlFyuQO9kbMR6QnrLd1d_6zo-L7bRAq9Q",
  authDomain: "projectshub.xyz",
  databaseURL: "https://left-things.firebaseio.com",
  projectId: "left-things",
  storageBucket: "left-things.appspot.com",
  messagingSenderId: "1094075127530",
  appId: "1:1094075127530:web:d85d55930871a8a194e993",
  measurementId: "G-2K39PXME92"
};

export const test = {
  apiKey: "AIzaSyA1YP5Zr5KVKXJXe3TPKmW3-RSlxTRhj7A",
  authDomain: "left-things-test.firebaseapp.com",
  databaseURL: "https://left-things-test.firebaseio.com",
  projectId: "left-things-test",
  storageBucket: "left-things-test.appspot.com",
  messagingSenderId: "896984526687",
  appId: "1:896984526687:web:1c0b15811a5a05ae2bd0ea",
  measurementId: "G-DVN179QQZY"
};

export const dev = {};
