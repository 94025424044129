import { watchProject } from "../clients/projects/projects";
import { useEffect, useState } from "react";

const cache = {};

export const useProject = id => {
  const [project, setProject] = useState(cache[id]);
  useEffect(() => {
    return watchProject(id, data => {
      setProject(data);
      cache[id] = data;
    });
  }, [id]);
  return project;
};
