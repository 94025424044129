import React, { Fragment } from "react";
import styled from "styled-components";
import { useFieldArray } from "react-hook-form";
import { ContactTypeSelect } from "./ContactTypeSelect";
import { ContactTextInputs } from "./ContactTextInputs";
import { Divider as MuiDivider, Button } from "@material-ui/core";
import { TextDivider } from "../../../forms/styles";
import { Add } from "@material-ui/icons";

const Root = styled.div``;

const LinkButton = styled(Button).attrs({
  variant: "outlined",
  type: "button",
  startIcon: <Add />
})`
  && {
    margin-top: 2rem;
  }
`;

const Divider = styled(MuiDivider)`
  && {
    margin-top: 2rem;
  }
`;

export const ContactDetails = ({
  control,
  errors,
  isSubmitting,
  registerField,
  watch,
  setValue
}) => {
  const { fields, append } = useFieldArray({ control, name: "contacts" });
  return (
    <Root>
      <TextDivider>Contact details</TextDivider>
      {fields.map((field, index) => {
        return (
          <Fragment key={index}>
            <ContactTypeSelect
              {...{
                index,
                isSubmitting,
                errors,
                registerField,
                watch,
                setValue
              }}
            />
            <ContactTextInputs
              {...{ index, isSubmitting, errors, watch, registerField }}
            />
            <Divider />
          </Fragment>
        );
      })}
      <LinkButton onClick={append} data-cy="addContact">
        Add {fields.length > 0 && "another"} contact / website
      </LinkButton>
    </Root>
  );
};
