import styled from "styled-components";

export const Fade = styled.div`
  position: absolute;
  bottom: 0px;

  display: block;

  width: 100%;
  height: 4rem;

  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 100%
  );
`;
