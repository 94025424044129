import React from "react";
import { FormHelperText } from "@material-ui/core";
import styled from "styled-components";

const Root = styled(FormHelperText).attrs({ error: true })`
  && {
    margin-bottom: 0.5rem;
  }
`;

export const Error = ({ error }) =>
  error?.message ? (
    <Root data-error-code={error?.type}>{error?.message}</Root>
  ) : null;
