import React from "react";
import { AppBarButton, showOnMobile } from "../styles";
import { useDispatch } from "react-redux";
import {
  setLoginOpen,
  setRegisterOpen
} from "../../../../data/redux/slices/auth";
import styled from "styled-components";
import { Menu as MenuIcon } from "@material-ui/icons";
import { MenuItem, Menu } from "@material-ui/core";

const MobileLoggedOutMenu = styled.div`
  ${showOnMobile}
`;

export const LoggedOutMenu = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = callback => () => {
    callback && callback();
    setAnchorEl(null);
  };

  return (
    <MobileLoggedOutMenu>
      <AppBarButton
        data-cy="loggedOutActionsMenu"
        aria-controls="logged-in-actions"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </AppBarButton>
      <Menu
        id="logged-out-actions-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose()}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <MenuItem
          data-cy="loginMenuButton"
          onClick={handleClose(() => dispatch(setLoginOpen(true)))}
        >
          Login
        </MenuItem>
        <MenuItem
          data-cy="registerMenuButton"
          onClick={handleClose(() => dispatch(setRegisterOpen(true)))}
        >
          Register
        </MenuItem>
      </Menu>
    </MobileLoggedOutMenu>
  );
};
