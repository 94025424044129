import firebase from "@firebase/app";
import mem from "mem";

export const addProfile = async ({ uid, email, displayName }) => {
  await firebase
    .database()
    .ref(`users/${uid}`)
    .set({ email, displayName, uid });
};

const isDisplayNameInUse = mem(async displayName => {
  const trace = firebase
    .performance()
    .trace("Registration: Check display name");
  trace.start();
  const response = await firebase
    .functions()
    .httpsCallable("isDisplayNameInUse")({ displayName });
  trace.stop();
  return response.data;
});

const isEmailInUse = mem(async email => {
  const trace = firebase.performance().trace("Registration: Check email");
  trace.start();
  const response = await firebase.functions().httpsCallable("isEmailInUse")({
    email
  });
  trace.stop();
  return response.data;
});

export const checkDuplicates = {
  displayName: isDisplayNameInUse,
  email: isEmailInUse,
  clearCache: () => {
    mem.clear(isDisplayNameInUse);
    mem.clear(isEmailInUse);
  }
};
