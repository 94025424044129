import React from "react";
import { FormButton } from "../../../forms/styles";
import { GoogleLogo } from "./GoogleLogo";
import { signInWithGoogle } from "../../../../data/firebase/auth/signIn";
import { useDispatch } from "react-redux";

export const ExternalAuthProviders = () => {
  const dispatch = useDispatch();
  return (
    <div>
      <FormButton
        style={{
          padding: 0,
          paddingRight: "1rem",
          backgroundColor: "white",
          marginTop: "0"
        }}
        variant="contained"
        onClick={signInWithGoogle(dispatch)}
      >
        <GoogleLogo />
        Sign in with Google
      </FormButton>
    </div>
  );
};
