import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

const AdditionalInfo = styled.div`
  min-height: 0.5rem;
  ${({ fixHeight }) => (fixHeight ? "height: 1.5rem;" : "")}
  padding: 1rem 0 0.5rem;
`;

export const RelatedIdeaInfo = ({ idea: { id, title } = {} }) => {
  return (
    <AdditionalInfo fixHeight={!!id}>
      {title && (
        <Typography color="textSecondary" variant="caption">
          Adding project related to: "{title}"
        </Typography>
      )}
    </AdditionalInfo>
  );
};
