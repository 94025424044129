import React from "react";
import { Link } from "react-router-dom";
import { PaperPageContent } from "../styles/layout";

const NotFoundPage = () => (
  <PaperPageContent title="404 - Page not found">
    Try the <Link to="/">Home page</Link>
  </PaperPageContent>
);

export { NotFoundPage };
