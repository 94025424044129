import React from "react";
import {
  SecondaryButton,
  DialogRoot,
  FormDivider
} from "../../../forms/styles";
import { RegisterForm } from "./RegisterForm";
import { ExternalAuthProviders } from "../externalAuth/ExternalAuthProviders";

export const Register = ({ isOpen, handleClose, switchForm }) => {
  return (
    <DialogRoot data-cy="register-dialog" open={isOpen} onClose={handleClose}>
      <ExternalAuthProviders />

      <FormDivider />

      <RegisterForm />

      <FormDivider />

      <SecondaryButton onClick={switchForm}>Login instead?</SecondaryButton>
    </DialogRoot>
  );
};
