import React from "react";
import { Link } from "react-router-dom";
import { PaperPageContent } from "../styles/layout";

const ItemNotFoundPage = () => (
  <PaperPageContent title="Item not found">
    Try going to the <Link to="/">Home page</Link>
    <span data-cy="item-not-found" />
  </PaperPageContent>
);

export { ItemNotFoundPage };
