import firebase from "@firebase/app";

export const ideasCollection = () => firebase.firestore().collection("ideas");
export const ideaRef = id => ideasCollection().doc(id);

export const postIdea = async ({ title, description }) => {
  const ref = await ideasCollection().add({
    title,
    description,
    likes: 0,
    createdBy: firebase.auth().currentUser.uid,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    changedAt: firebase.firestore.FieldValue.serverTimestamp()
  });
  return ref.id;
};

export const getIdea = async id => {
  const ref = ideaRef(id);
  const snap = await ref.get();
  if (!snap.exists) {
    return { error: "item-not-found" };
  }
  return {
    data: {
      ...snap.data(),
      id
    }
  };
};

export const watchIdea = (id, set) => {
  const ref = ideaRef(id);
  return ref.onSnapshot({ includeMetadataChanges: true }, doc => {
    if (!doc.exists) {
      return set({ error: "item-not-found" });
    }
    const mappedResults = {
      id: doc.id,
      ...doc.data(),
      pending: doc.metadata.hasPendingWrites
    };
    set({
      data: mappedResults
    });
  });
};

export const getIdeas = async () => {
  const ref = ideasCollection();
  const snap = await ref.get();
  return snap.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

export const watchIdeas = set => {
  const ref = ideasCollection().orderBy("likes", "desc");
  return ref.onSnapshot({ includeMetadataChanges: true }, snap => {
    const mappedResults = snap.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      pending: doc.metadata.hasPendingWrites
    }));
    set(mappedResults);
  });
};
