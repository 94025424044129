import firebase from "@firebase/app";
import "@firebase/database";
import "@firebase/firestore";
import "@firebase/functions";
import "@firebase/performance";
import "@firebase/auth";
import "@firebase/analytics";
import { test, dev, prod } from "./env";

export const initialiseFirebase = () => {
  let env = test;
  switch (process.env.REACT_APP_ENV) {
    case "production":
      env = prod;
      break;
    case "development":
      env = dev;
      break;
    default:
      break;
  }
  if (!env.apiKey) {
    throw new Error(
      "Please add firebase config to `src/data/firebase/init/env.js`. Find it in the firebase console in project settings -> your apps."
    );
  }
  firebase.initializeApp(env);
  firebase.performance();
  firebase.analytics();
  if (window.Cypress) {
    window.fb = firebase;
  }
};
