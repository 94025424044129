import firebase from "@firebase/app";
import { projectRef } from "./projects";

const userLikesCollection = () => firebase.firestore().collection("userLikes");
const currentUserLikes = () => {
  const user = firebase.auth().currentUser;
  return userLikesCollection().doc(user.uid);
};

const incrementProjectLikesCount = async id => {
  const ref = projectRef(id);
  return ref.update({ likes: firebase.firestore.FieldValue.increment(1) });
};

const addToUserLikes = async id => {
  const ref = currentUserLikes();
  return ref.set(
    { likes: firebase.firestore.FieldValue.arrayUnion(id) },
    { merge: true }
  );
};

export const likeProject = async id => {
  await Promise.all([incrementProjectLikesCount(id), addToUserLikes(id)]);
};

const removeFromUserLikes = async id => {
  const ref = currentUserLikes();
  return ref.set(
    { likes: firebase.firestore.FieldValue.arrayRemove(id) },
    { merge: true }
  );
};

const decrementProjectLikesCount = async id => {
  const ref = projectRef(id);
  return ref.update({ likes: firebase.firestore.FieldValue.increment(-1) });
};

export const unlikeProject = async id => {
  await Promise.all([decrementProjectLikesCount(id), removeFromUserLikes(id)]);
};

export const watchUserProjectLikes = set => {
  const ref = currentUserLikes();
  const unsubscribe = ref.onSnapshot(doc => {
    if (doc.exists) {
      set(doc.data().likes);
    }
  });

  return () => {
    unsubscribe();
    set([]);
  };
};
