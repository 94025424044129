import React from "react";
import { Link } from "react-router-dom";
import { Link as MuiLink } from "@material-ui/core";
import styled from "styled-components";

const Root = styled(MuiLink)`
  & + & {
    margin-left: 1rem;
  }
`;

export const PostLink = ({ to, children }) => {
  return (
    <Root component={Link} to={to} variant="body2" color="textSecondary">
      {children}
    </Root>
  );
};
