import React from "react";
import { useForm } from "react-hook-form";
import { signInUser } from "../../../../data/firebase/auth/auth";
import { getError, mapErrorFields } from "../../../forms/validation";
import { Error } from "../../../forms/Error";
import { TextInput, Submit } from "../../../forms/styles";

export const LoginForm = () => {
  const {
    register: registerField,
    handleSubmit,
    errors,
    formState: { isSubmitting },
    setError
  } = useForm();
  const onSubmit = async values => {
    try {
      await signInUser(values);
    } catch (error) {
      setError([getError(error)]);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        label="Email"
        name="email"
        inputRef={registerField({ required: "Email is required" })}
        disabled={isSubmitting}
        {...mapErrorFields(errors.email)}
      />
      <TextInput
        label="Password"
        name="password"
        type="password"
        inputRef={registerField({ required: "Password is required" })}
        disabled={isSubmitting}
        {...mapErrorFields(errors.password)}
      />
      <Error error={errors.form} />
      <Submit type="submit" disabled={isSubmitting}>
        Login
      </Submit>
    </form>
  );
};
