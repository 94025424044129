import firebase from "@firebase/app";
import { ideaRef } from "./ideas";

const userLikesCollection = () => firebase.firestore().collection("userLikes");
const currentUserLikes = () => {
  const user = firebase.auth().currentUser;
  return userLikesCollection().doc(user.uid);
};

const incrementIdeaLikesCount = async id => {
  const ref = ideaRef(id);
  return ref.update({ likes: firebase.firestore.FieldValue.increment(1) });
};

const addToUserLikes = async id => {
  const ref = currentUserLikes();
  return ref.set(
    { likes: firebase.firestore.FieldValue.arrayUnion(id) },
    { merge: true }
  );
};

export const likeIdea = async id => {
  await Promise.all([incrementIdeaLikesCount(id), addToUserLikes(id)]);
};

const removeFromUserLikes = async id => {
  const ref = currentUserLikes();
  return ref.set(
    { likes: firebase.firestore.FieldValue.arrayRemove(id) },
    { merge: true }
  );
};

const decrementIdeaLikesCount = async id => {
  const ref = ideaRef(id);
  return ref.update({ likes: firebase.firestore.FieldValue.increment(-1) });
};

export const unlikeIdea = async id => {
  await Promise.all([decrementIdeaLikesCount(id), removeFromUserLikes(id)]);
};

export const watchUserIdeaLikes = set => {
  const ref = currentUserLikes();
  const unsubscribe = ref.onSnapshot(doc => {
    if (doc.exists) {
      set(doc.data().likes);
    }
  });

  return () => {
    unsubscribe();
    set([]);
  };
};
