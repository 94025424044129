import { watchIdeas } from "../clients/ideas/ideas";
import { useEffect, useState } from "react";

const cache = {};

export const useIdeas = () => {
  const [ideas, setIdeas] = useState(cache.value);
  useEffect(() => {
    return watchIdeas(data => {
      setIdeas(data);
      cache.value = data;
    });
  }, []);
  return ideas;
};
