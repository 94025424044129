import React from "react";
import { useForm } from "react-hook-form";
import { getError, mapErrorFields } from "../../forms/validation";
import { Error } from "../../forms/Error";
import { TextInput, Submit } from "../../forms/styles";
import { postProject } from "../../../data/firebase/clients/projects/projects";
import { useHistory, useParams } from "react-router-dom";
import { PaperPageContent } from "../../styles/layout";
import { useIdeaOnce } from "../../../data/firebase/hooks/useIdea";
import { RelatedIdeaInfo } from "./RelatedIdeaInfo";
import {
  titleValidations,
  descriptionValidations
} from "../../forms/validation/inputs";
import { ContactDetails } from "./contactDetails/ContactDetails";

export const NewProject = () => {
  const { ideaId } = useParams();
  const { data: idea } = useIdeaOnce(ideaId) || {};

  const {
    control,
    register: registerField,
    handleSubmit,
    errors,
    formState: { isSubmitting },
    setError,
    watch,
    setValue
  } = useForm();
  const history = useHistory();
  const onSubmit = async values => {
    try {
      const newId = await postProject(values, idea);
      history.replace(`/projects/${newId}`);
    } catch (error) {
      setError([getError(error)]);
    }
  };

  return (
    <PaperPageContent title="New Project" paddingTop={false}>
      <RelatedIdeaInfo idea={idea} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label="Title"
          name="title"
          inputRef={registerField(titleValidations)}
          disabled={isSubmitting}
          variant="outlined"
          fullWidth={true}
          style={{ marginTop: 0 }}
          {...mapErrorFields(errors.title)}
        />
        <TextInput
          label="Description"
          name="description"
          multiline={true}
          inputRef={registerField(descriptionValidations)}
          disabled={isSubmitting}
          variant="outlined"
          fullWidth={true}
          rows="10"
          {...mapErrorFields(errors.description)}
        />
        <ContactDetails
          {...{
            control,
            errors,
            isSubmitting,
            registerField,
            watch,
            setValue
          }}
        />
        <Error error={errors.form} />
        <Submit type="submit" disabled={isSubmitting}>
          Submit
        </Submit>
      </form>
    </PaperPageContent>
  );
};
