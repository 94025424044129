import { useEffect } from "react";
import { useDispatch } from "react-redux";
import firebase from "@firebase/app";
import { userFound, userNotFound } from "../../redux/slices/user";

const sanitizeUser = ({ displayName, email, emailVerified, uid }) => ({
  displayName,
  email,
  emailVerified,
  uid
});

export const useAuth = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        const sanitizedUser = sanitizeUser(user);
        dispatch(userFound(sanitizedUser));
      } else {
        dispatch(userNotFound());
      }
    });
  }, [dispatch]);
};
