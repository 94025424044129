import React from "react";
import { Typography } from "@material-ui/core";
import { LikesSection } from "./LikesSection";
import { Fade } from "../styles/fade";
import { RenderContent } from "../styles/RenderContent";
import {
  Root,
  ContentSection,
  SimpleLink,
  DescriptionRoot,
  InfoFooter
} from "../posts/styles";
import { PostLink } from "../styles/postLinks";

const IdeaListItem = ({
  id,
  fullPost,
  title,
  likes,
  description,
  linkedProjectIds,
  pending
}) => {
  const ideaPage = `/ideas/${id}`;
  return (
    <Root data-cy-idea={id}>
      <ContentSection>
        <Typography
          style={{ marginBottom: "1rem" }}
          gutterBottom={true}
          variant="body2"
        >
          <SimpleLink to={ideaPage}>{title}</SimpleLink>
        </Typography>
        <DescriptionRoot fullPost={fullPost}>
          <RenderContent content={description} />
          {!fullPost && <Fade />}
        </DescriptionRoot>
        <InfoFooter>
          {!fullPost && <PostLink to={ideaPage}>See more</PostLink>}
          <PostLink to={ideaPage + "#projects"}>
            {linkedProjectIds?.length || 0} Project
            {linkedProjectIds?.length !== 1 && "s"}
          </PostLink>
        </InfoFooter>
      </ContentSection>
      <LikesSection likes={likes} ideaId={id} />
      <span data-cy-pending={pending} />
    </Root>
  );
};

export { IdeaListItem };
