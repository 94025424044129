import styled, { css } from "styled-components";
import { onePixelFor } from "../styles/utils";
import { Link } from "react-router-dom";

export const SimpleLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;

export const Root = styled.div`
  && {
    background-color: white;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    & + & {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      ${onePixelFor("border-top-width")}
    }
    display: flex;
    box-sizing: content-box;
  }
`;

export const ContentSection = styled.div`
  && {
    flex-grow: 1;
    padding: 1rem;
  }
`;

export const DescriptionRoot = styled.div`
  && {
    ${({ fullPost }) =>
      fullPost
        ? ""
        : css`
            max-height: 15rem;
            overflow: hidden;
            position: relative;
          `}
    min-height: 5rem;
  }
`;

export const InfoFooter = styled.div`
  && {
    min-height: 1rem;
  }
`;
