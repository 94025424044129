import { Button } from "@material-ui/core";
import styled, { css } from "styled-components";

export const AppBarButton = styled(Button)`
  && {
    text-transform: none;
    border-radius: 0;
    height: 4.5rem;
    color: black;
    padding: 0.5rem;

    ${props => props.theme.breakpoints.down("xs")} {
      height: 3.5rem;
    }
  }
`;

export const hideOnMobile = css`
  && {
    ${props => props.theme.breakpoints.down("sm")} {
      display: none;
    }
  }
`;

export const showOnMobile = css`
  && {
    display: none;
    ${props => props.theme.breakpoints.down("sm")} {
      display: inherit;
    }
  }
`;
