import firebase from "@firebase/app";
import { updateProfile } from "../../redux/slices/user";
import { addProfile } from "../clients/clients";
window.perfLog = window.perfLog || [];

const updateAuthProfile = async ({ displayName }) => {
  const traceUP = firebase
    .performance()
    .trace("Registration: Update auth profile");
  traceUP.start();
  const currentUser = firebase.auth().currentUser;
  await currentUser.updateProfile({ displayName });
  traceUP.stop();
};

const addProfileToDb = async ({ displayName, email }) => {
  const traceAP = firebase
    .performance()
    .trace("Registration: Add profile to db");
  traceAP.start();
  const currentUser = firebase.auth().currentUser;
  await addProfile({ displayName, email, uid: currentUser.uid });
  traceAP.stop();
};

const createUser = async ({ password, email }) => {
  const trace = firebase.performance().trace("Registration: Create user");
  trace.start();
  await firebase.auth().createUserWithEmailAndPassword(email, password);
  trace.stop();
};

export const updateUserProfiles = ({ displayName, email }) =>
  Promise.all([
    updateAuthProfile({ displayName }),
    addProfileToDb({ displayName, email })
  ]);

export const registerUser = async (
  { displayName, email, password },
  dispatch
) => {
  const traceS = firebase.performance().trace("Registration: submit");
  traceS.start();

  await createUser({ email, password });

  await updateUserProfiles({ displayName, email });

  traceS.stop();

  dispatch(updateProfile({ displayName }));
};

export const signInUser = async ({ email, password }) => {
  const traceS = firebase.performance().trace("Login: submit");
  traceS.start();
  await firebase.auth().signInWithEmailAndPassword(email, password);
  traceS.stop();
};

export const logOut = () => firebase.auth().signOut();
