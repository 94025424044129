import React from "react";
import styled from "styled-components";
import { AppBarButton } from "./userActions/styles";
import { NavLink as RRNavLink } from "react-router-dom";

const Root = styled.div`
  && {
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
  }
`;

const NavLink = styled(AppBarButton).attrs({ component: RRNavLink })`
  && {
    text-transform: uppercase;
    &.active {
      border-bottom: solid 0.25rem #ff0000;
      padding-top: 0.75rem; /* keeps the text centered */
    }

    ${props => props.theme.breakpoints.down("xs")} {
      height: 3rem;
    }
  }
`;

export const NavLinks = () => {
  return (
    <Root>
      <div>
        <NavLink
          to="/"
          exact
          isActive={(match, location) =>
            match || location.pathname.startsWith("/ideas")
          }
          data-cy="navLink"
        >
          Ideas
        </NavLink>
        <NavLink to="/projects" data-cy="navLink">
          Projects
        </NavLink>
        <NavLink to="/organizations" data-cy="navLink">
          {/* Organizations + about */}
          More
        </NavLink>
      </div>
    </Root>
  );
};
