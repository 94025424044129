import React from "react";
import { useForm } from "react-hook-form";
import { getError, mapErrorFields } from "../../forms/validation";
import { Error } from "../../forms/Error";
import { TextInput, Submit } from "../../forms/styles";
import { postIdea } from "../../../data/firebase/clients/ideas/ideas";
import { useHistory } from "react-router-dom";
import { PaperPageContent } from "../../styles/layout";
import {
  titleValidations,
  descriptionValidations
} from "../../forms/validation/inputs";

export const NewIdea = () => {
  const {
    register: registerField,
    handleSubmit,
    errors,
    formState: { isSubmitting },
    setError
  } = useForm();
  const history = useHistory();
  const onSubmit = async values => {
    try {
      const newId = await postIdea(values);
      history.replace(`/ideas/${newId}`);
    } catch (error) {
      setError([getError(error)]);
    }
  };

  return (
    <PaperPageContent title="New Idea" paddingTop={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label="Title"
          name="title"
          inputRef={registerField(titleValidations)}
          disabled={isSubmitting}
          variant="outlined"
          fullWidth={true}
          {...mapErrorFields(errors.title)}
        />
        <TextInput
          label="Description"
          name="description"
          multiline={true}
          inputRef={registerField(descriptionValidations)}
          disabled={isSubmitting}
          variant="outlined"
          fullWidth={true}
          rows="10"
          {...mapErrorFields(errors.description)}
        />
        <Error error={errors.form} />
        <Submit type="submit" disabled={isSubmitting}>
          Submit
        </Submit>
      </form>
    </PaperPageContent>
  );
};
