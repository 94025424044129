import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Typography, IconButton } from "@material-ui/core";
import { ArrowDropUp } from "@material-ui/icons";
import { selectIsLoggedIn } from "../../data/redux/slices/user";
import { useSelector, useDispatch } from "react-redux";
import {
  likeIdea,
  unlikeIdea,
  watchUserIdeaLikes
} from "../../data/firebase/clients/ideas/likes";
import { excludeProps } from "../utils/excludeProps";
import { unauthorisedAction } from "../../data/redux/slices/auth";
import { onePixelFor } from "../styles/utils";

const Root = styled.div`
  && {
    background-color: #f3f6f8;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    ${onePixelFor("border-left-width")}
    text-align: center;
    min-width: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Spacer = styled.div`
  && {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
`;

const Score = styled.div`
  && {
  }
`;

const ProtectedIconButton = excludeProps("hasLiked")(IconButton);
const LikeButton = styled(ProtectedIconButton)`
  && {
    padding: 0.25rem;
    ${({ hasLiked, theme }) => {
      if (hasLiked) {
        return css`
          color: ${theme.palette.primary.main};
        `;
      }
    }}
  }
`;

export const LikesSection = ({ likes, ideaId }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [likedIdeas, setLikedIdeas] = useState([]);
  useEffect(() => {
    if (isLoggedIn) {
      return watchUserIdeaLikes(setLikedIdeas);
    }
  }, [isLoggedIn]);
  const hasLiked = likedIdeas.includes(ideaId);
  const dispatch = useDispatch();
  return (
    <Root>
      <Spacer>
        <Spacer></Spacer>
        <LikeButton
          data-cy="like"
          data-cy-liked={hasLiked}
          hasLiked={hasLiked}
          onClick={() => {
            if (!isLoggedIn) {
              return dispatch(unauthorisedAction());
            }
            hasLiked ? unlikeIdea(ideaId) : likeIdea(ideaId);
          }}
        >
          <ArrowDropUp />
        </LikeButton>
      </Spacer>
      <Score>
        <Typography variant="body2" data-cy="likes">
          {likes}
        </Typography>
      </Score>
      <Spacer>
        <LikeButton style={{ visibility: "hidden" }}>
          <ArrowDropUp />
        </LikeButton>
      </Spacer>
    </Root>
  );
};
