import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import { LikesSection } from "./LikesSection";
import { DisplayContactDetails } from "./contactDetails/DisplayContactDetails";
import {
  Root,
  ContentSection,
  SimpleLink,
  DescriptionRoot,
  InfoFooter
} from "../posts/styles";
import { Fade } from "../styles/fade";
import { RenderContent } from "../styles/RenderContent";
import { PostLink } from "../styles/postLinks";

export const ProjectListItem = ({
  id,
  fullPost,
  title,
  likes,
  contacts,
  description,
  pending
}) => {
  const projectPagePath = `/projects/${id}`;
  return (
    <Root>
      <ContentSection>
        <Typography gutterBottom={true} variant="body2">
          <SimpleLink to={projectPagePath}>{title}</SimpleLink>
        </Typography>
        <DescriptionRoot fullPost={fullPost}>
          <RenderContent content={description} />
          {!fullPost && <Fade />}
        </DescriptionRoot>
        <InfoFooter></InfoFooter>
        <InfoFooter>
          {fullPost && <DisplayContactDetails contacts={contacts} />}
          {!fullPost && (
            <Fragment>
              <PostLink to={projectPagePath}>See more</PostLink>
              <PostLink to={projectPagePath + "#contacts"}>
                {contacts?.length || 0} Contact/link
                {contacts?.length !== 1 && "s"}
              </PostLink>
            </Fragment>
          )}
        </InfoFooter>
      </ContentSection>
      <LikesSection likes={likes} projectId={id} />
      <span data-cy-pending={pending} />
    </Root>
  );
};
