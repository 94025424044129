import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
`;

export const Loading = () => {
  return (
    <Root>
      <CircularProgress />
    </Root>
  );
};
