import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#FF0000"
    },
    secondary: {
      main: "#008060"
    }
  },
  props: {
    MuiTextField: {
      color: "secondary"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 720,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    fontWeightBold: 800
  }
});
