import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    userFound(state, action) {
      return {
        ...action.payload,
        isLoaded: true
      };
    },
    userNotFound() {
      return { isLoaded: true };
    },
    updateProfile(state, action) {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const selectUser = state => state.user;
export const selectDisplayName = state => selectUser(state).displayName;
export const selectIsUserLoaded = state => selectUser(state).isLoaded;
export const selectIsLoggedIn = state => !!selectDisplayName(state);

const { actions, reducer: user } = userSlice;

export const { updateProfile, userFound, userNotFound } = actions;

export { user };
