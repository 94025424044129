import React, { Fragment } from "react";
import { LoggedOutMenu } from "./LoggedOutMenu";
import { LoggedOutButtons } from "./LoggedOutButtons";

export const LoggedOutActions = () => {
  return (
    <Fragment>
      <LoggedOutButtons />
      <LoggedOutMenu />
    </Fragment>
  );
};
