import React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { PageContent } from "../../styles/layout";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { AddLinkedProject } from "./AddLinkedProject";
import { Fade } from "../../styles/fade";

const Root = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0.5rem 0.5rem 0.5rem;
  overflow-x: auto;
`;

const SmallCard = styled(Card)`
  && {
    width: 15rem;
    height: 12.5rem;
    overflow-y: hidden;
    position: relative;
  }
`;

const NoStyleLink = styled(Link)`
  && {
    text-decoration: none;
    ${({ disabled }) =>
      disabled
        ? css`
            cursor: default;
            pointer-events: none;
            filter: opacity(75%);
          `
        : ""}
    & + & {
      margin-left: 1rem;
    }
  }
`;

export const LinkedProjects = ({ ideaId, projects }) => {
  let list = Object.values(projects || {});
  const noProjects = !list[0];
  if (noProjects) {
    list = [
      {
        id: "placeHolder",
        title: "No related projects yet",
        description: "Click (+) to add a related project"
      }
    ];
  }
  return (
    <PageContent>
      <Root>
        {list.map(project => (
          <NoStyleLink
            key={project.id}
            disabled={noProjects}
            onClick={noProjects ? event => event.preventDefault() : null}
            to={`/projects/${project.id}`}
          >
            <SmallCard>
              <CardHeader title={project.title} />
              <CardContent>{project.description}</CardContent>
              <Fade />
            </SmallCard>
          </NoStyleLink>
        ))}
        <AddLinkedProject {...{ ideaId }} />
      </Root>
    </PageContent>
  );
};
