import { watchProjects } from "../clients/projects/projects";
import { useEffect, useState } from "react";

const cache = {};

export const useProjects = () => {
  const [projects, setProjects] = useState(cache.value);
  useEffect(() => {
    return watchProjects(data => {
      setProjects(data);
      cache.value = data;
    });
  }, []);
  return projects;
};
