import React from "react";
import { Link } from "react-router-dom";
import { PaperPageContent } from "../styles/layout";
import { Typography } from "@material-ui/core";

const ErrorPage = () => (
  <PaperPageContent title="Error">
    <Typography variant="body1">Something went wrong</Typography>
    <Typography variant="body1">
      Please go back and try again or go to the <Link to="/">Home page</Link>
    </Typography>
    <Typography variant="body1">
      Contact support if this keeps happening
    </Typography>
  </PaperPageContent>
);

export { ErrorPage };
