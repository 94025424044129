import merge from "lodash.merge";

const knownErrors = {
  "auth/email-already-in-use": {
    name: "email",
    message: "Email address in use",
    type: "auth/email-already-in-use"
  },
  "auth/invalid-email": {
    name: "email",
    message: "Email address invalid",
    type: "auth/invalid-email"
  },
  "auth/weak-password": {
    name: "password",
    type: "auth/weak-password"
  }
};

const mapServerError = ({ code, message }) => ({
  type: code,
  message,
  name: "form"
});

export const getError = error => {
  const overrides = knownErrors[error.code];
  const serverError = mapServerError(error);
  return merge({}, serverError, overrides);
};

export const mapErrorFields = error => {
  if (!error) {
    return {};
  }
  return {
    error: !!error.message,
    helperText: error.message,
    "data-error-code": error.type
  };
};
