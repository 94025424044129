import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { LoggedOutActions } from "./loggedOut/LoggedOutActions";
import { LoggedInActions } from "./LoggedInActions";
import { selectIsLoggedIn } from "../../../data/redux/slices/user";

const Root = styled.div`
  && {
  }
`;

export const UserActions = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  return <Root>{isLoggedIn ? <LoggedInActions /> : <LoggedOutActions />}</Root>;
};
