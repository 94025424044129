import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { AccountCircle, Menu as MenuIcon } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { selectDisplayName } from "../../../data/redux/slices/user";
import styled from "styled-components";
import { logOut } from "../../../data/firebase/auth/auth";
import { AppBarButton, showOnMobile, hideOnMobile } from "./styles";

const AccountIcon = styled(AccountCircle)`
  margin-left: 0.5rem;
`;

const ProfileButtonContent = styled.div`
  ${hideOnMobile}
`;

const MenuButtonContent = styled.span`
  ${showOnMobile}
`;

export const LoggedInActions = () => {
  const displayName = useSelector(selectDisplayName);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = callback => () => {
    callback && callback();
    setAnchorEl(null);
  };

  return (
    <div>
      <AppBarButton
        data-cy="loggedInActions"
        aria-controls="logged-in-actions"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ProfileButtonContent className="MuiButton-label">
          {displayName} <AccountIcon />
        </ProfileButtonContent>
        <MenuButtonContent>
          <MenuIcon />
        </MenuButtonContent>
      </AppBarButton>
      <Menu
        id="logged-in-actions"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose()}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <MenuItem onClick={handleClose(logOut)}>Logout</MenuItem>
      </Menu>
    </div>
  );
};
