import React from "react";
import { createElement } from "react";
import { Typography, ListItem, List, ListItemIcon } from "@material-ui/core";
import { BlurCircular, DataUsage } from "@material-ui/icons";
import marksy from "marksy";
import styled from "styled-components";

const StyledLink = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer"
})`
  && {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;
export const renderMarkdown = marksy({
  createElement,
  elements: {
    a: StyledLink,
    p({ children }) {
      return (
        <Typography
          style={{ marginBottom: "1rem" }}
          gutterBottom={true}
          variant="body2"
          color="textSecondary"
        >
          {children}
        </Typography>
      );
    },
    ul({ children }) {
      return (
        <List style={{ paddingTop: 0, marginTop: "-0.5rem" }} dense={true}>
          {children.map(child => {
            if (!child?.props?.children) {
              return null;
            }
            return (
              <ListItem key={child.key}>
                <ListItemIcon style={{ minWidth: "1.5rem" }}>
                  <BlurCircular style={{ fontSize: "0.8rem" }} />
                </ListItemIcon>
                <Typography variant="body2" color="textSecondary">
                  {child?.props?.children}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      );
    }
  }
});
