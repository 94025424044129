import { Dialog, Button, TextField } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";

export const TextInput = styled(TextField)`
  && {
    display: block;
    margin-top: 2rem;
  }
`;

export const Submit = styled(Button).attrs({
  variant: "contained",
  color: "primary"
})`
  && {
    margin-top: 2rem;
  }
`;

export const FormButton = styled(Button).attrs()`
  && {
    margin-top: 2rem;
    text-transform: none;
  }
`;

export const SecondaryButton = styled(FormButton).attrs({
  color: "secondary",
  variant: "contained"
})``;

export const DialogRoot = styled(Dialog)`
  && {
    .MuiDialog-paper {
      padding: 2rem;
      text-align: center;
    }
  }
`;

export const TextDivider = styled.div`
  color: rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  &::before {
    margin-right: 0.5rem;
    ${({ align }) => {
      if (align === "left") {
        return css`
          flex-grow: 0;
          min-width: 2rem;
        `;
      }
      return "";
    }}
  }
  &::after {
    margin-left: 0.5rem;
  }
`;

export const FormDivider = () => <TextDivider>Or</TextDivider>;
