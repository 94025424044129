import { Language, Email, Twitter } from "@material-ui/icons";

export const contactTypesContent = {
  website: {
    id: "website",
    nameLabel: "Name",
    valueLabel: "Web address / url",
    defaultName: "Website",
    example: "Contact form",
    icon: Language
  },
  email: {
    id: "email",
    nameLabel: "Name",
    valueLabel: "Email address",
    defaultName: "Email",
    example: "Volunteering",
    icon: Email
  },
  twitter: {
    id: "twitter",
    nameLabel: "Name",
    valueLabel: "Twitter handle",
    defaultName: "Twitter",
    example: "Main account",
    icon: Twitter
  }
};
