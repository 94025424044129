import React, { Fragment } from "react";
import styled from "styled-components";
import { contactTypesContent } from "../../content/contactTypes";
import { TextDivider } from "../../forms/styles";
import { DisplayContactValue } from "./DisplayContactValue";

const Root = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.5rem;
  }
`;

const Contact = styled.div`
  && {
    color: ${({ theme }) => theme.palette.text.secondary};
    padding: 1rem 1rem 1rem 0;
    display: flex;
    flex-grow: 1;
    width: 15rem;
  }
`;

const ContactType = styled.div`
  && {
    display: flex;
    align-items: center;
    padding-right: 1rem;
  }
`;

const ContactNameAndValue = styled.div`
  && {
    overflow: hidden;
  }
`;

const ContactName = styled.div`
  && {
    margin-bottom: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ContactValue = styled.div`
  && {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DisplayContactDetails = ({ contacts = [] }) => {
  return (
    <Fragment>
      <TextDivider align="left">Contacts / Links</TextDivider>
      <Root>
        {contacts.map(contact => {
          const Icon = contactTypesContent[contact.type].icon;
          return (
            <Contact key={contact.id}>
              <ContactType>
                <Icon color="primary" />
              </ContactType>
              <ContactNameAndValue>
                <ContactName>{contact.name}</ContactName>
                <ContactValue>
                  <DisplayContactValue {...contact} />
                </ContactValue>
              </ContactNameAndValue>
            </Contact>
          );
        })}
        {!contacts[0] && (
          <Contact>
            <ContactName>No contacts / links</ContactName>
          </Contact>
        )}
      </Root>
    </Fragment>
  );
};
