import firebase from "@firebase/app";
import { updateUserProfiles } from "./auth";
import { updateProfile } from "../../redux/slices/user";
import uniqid from "uniqid";

export const signInWithGoogle = dispatch => async () => {
  var provider = new firebase.auth.GoogleAuthProvider();
  const result = await firebase.auth().signInWithPopup(provider);

  if (result.additionalUserInfo.isNewUser) {
    const {
      user: { displayName = uniqid(), email }
    } = result;
    const newDisplayName = displayName.replace(/[^a-z0-9_]/gi, "_");
    await updateUserProfiles({ displayName: newDisplayName, email });
    dispatch(updateProfile({ displayName: newDisplayName }));
  }
};
