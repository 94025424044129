import React from "react";
import { Register } from "../register/Register";
import { Login } from "../login/Login";
import { AppBarButton, hideOnMobile } from "../styles";
import { useSelector, useDispatch } from "react-redux";
import {
  selectIsLoginOpen,
  selectIsRegisterOpen,
  setLoginOpen,
  setRegisterOpen,
  switchAuth
} from "../../../../data/redux/slices/auth";
import styled from "styled-components";

const LoggedOutButtonsRoot = styled.div`
  ${hideOnMobile}
`;

export const LoggedOutButtons = () => {
  const isRegisterOpen = useSelector(selectIsRegisterOpen);
  const isLoginOpen = useSelector(selectIsLoginOpen);
  const dispatch = useDispatch();
  const setRegisterOpenTo = value => dispatch(setRegisterOpen(value));
  const setLoginOpenTo = value => dispatch(setLoginOpen(value));
  const switchForms = () => dispatch(switchAuth());
  return (
    <LoggedOutButtonsRoot>
      <AppBarButton onClick={() => setRegisterOpenTo(true)}>
        Register
      </AppBarButton>
      <Register
        isOpen={isRegisterOpen}
        handleClose={() => setRegisterOpenTo(false)}
        switchForm={switchForms}
      />
      <AppBarButton onClick={() => setLoginOpenTo(true)}>Login</AppBarButton>
      <Login
        isOpen={isLoginOpen}
        handleClose={() => setLoginOpenTo(false)}
        switchForm={switchForms}
      />
    </LoggedOutButtonsRoot>
  );
};
