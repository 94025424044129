import React from "react";
import { Component } from "react";
import { ErrorPage } from "./errorPages/ErrorPage";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log("here");
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export const withErrorBoundary = Page => props => (
  <ErrorBoundary>
    <Page {...props} />
  </ErrorBoundary>
);
