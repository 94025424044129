import React from "react";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectIsLoggedIn } from "../../../data/redux/slices/user";
import { unauthorisedAction } from "../../../data/redux/slices/auth";
import { Fab } from "@material-ui/core";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
`;

export const AddLinkedProject = ({ ideaId }) => {
  let actionProps = { component: Link, to: `/ideas/${ideaId}/projects/new` };
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  if (!isLoggedIn) {
    actionProps = { onClick: () => dispatch(unauthorisedAction()) };
  }

  return (
    <Root>
      <Fab
        {...actionProps}
        color="primary"
        data-cy="addLinkedProject"
        title="Add a related project"
        aria-label="Add a related project"
      >
        <Add />
      </Fab>
    </Root>
  );
};
