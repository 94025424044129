import React from "react";
import styled, { css } from "styled-components";
import { Typography } from "@material-ui/core";

export const pageContentMarginTop = css`
  margin-top: 2rem;
  ${props => props.theme.breakpoints.down("xs")} {
    margin-top: 1rem;
  }
`;

export const PageContentMarginTop = styled.div`
  ${pageContentMarginTop}
`;

export const pageContentHorizontal = css`
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  ${props => props.theme.breakpoints.up("sm")} {
    padding: 0 1rem;
  }
`;

export const PageContentWidth = styled.div`
  ${pageContentHorizontal}
`;

export const Paper = styled.div`
  && {
    background-color: white;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    padding: 2rem;
    ${({ paddingTop }) =>
      paddingTop
        ? ""
        : "padding-top: 1px;"} /* stops some weird margin collapse */
  }
`;

export const HeadingRoot = styled.div`
  padding: 0 0 1rem 2rem;
  display: flex;
`;

export const Grow = styled.div`
  flex-grow: 1;
`;

export const PageContent = ({ children }) => (
  <PageContentWidth>
    <PageContentMarginTop>{children}</PageContentMarginTop>
  </PageContentWidth>
);

export const PaperPageContent = ({ children, title, paddingTop = true }) => (
  <PageContentWidth>
    <PageContentMarginTop>
      {title && (
        <HeadingRoot>
          <Typography data-cy="pageTitle" variant="h5">
            {title}
          </Typography>
        </HeadingRoot>
      )}
      <Paper paddingTop={paddingTop}>{children}</Paper>
    </PageContentMarginTop>
  </PageContentWidth>
);
