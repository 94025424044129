import React from "react";
import styled from "styled-components";
import { Link } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";

const displayUrl = url => {
  let result = url.replace(/^(https:\/\/)/, "");
  result = result.replace(/(\/)$/, "");
  return result.replace(/^(www.)/, "");
};

const NewTabIcon = styled(OpenInNew)`
  && {
    margin-left: 0.05rem;
    font-size: 0.8rem;
  }
`;

const DefaultDisplay = ({ value }) => value;

const EmailDisplay = ({ value }) => (
  <Link
    href={`mailto:${value}`}
    color="textSecondary"
    rel="noopener noreferrer"
    target="_blank"
  >
    {value}
    <NewTabIcon />
  </Link>
);

const WebsiteDisplay = ({ value }) => (
  <Link
    href={value}
    color="textSecondary"
    rel="noopener noreferrer"
    target="_blank"
  >
    {displayUrl(value)}
    <NewTabIcon />
  </Link>
);

const TwitterDisplay = ({ value }) => (
  <Link
    href={`https://twitter.com/${value}`}
    color="textSecondary"
    rel="noopener noreferrer"
    target="_blank"
  >
    @{value}
    <NewTabIcon />
  </Link>
);

const componentForType = {
  email: EmailDisplay,
  website: WebsiteDisplay,
  twitter: TwitterDisplay
};

export const DisplayContactValue = ({ type, value }) => {
  let Component = componentForType[type];
  if (!Component) {
    Component = DefaultDisplay;
  }
  return <Component value={value} />;
};
