import React from "react";
import { IdeaListItem } from "./IdeaListItem";
import { AddIdeaButton } from "./AddIdeaButton";
import { Loading } from "../layout/Loading";
import { Typography } from "@material-ui/core";
import {
  PageContentWidth,
  Grow,
  PageContentMarginTop,
  HeadingRoot
} from "../styles/layout";
import { useIdeas } from "../../data/firebase/hooks/useIdeas";

const Ideas = () => {
  const ideas = useIdeas();
  return (
    <PageContentWidth>
      <PageContentMarginTop>
        <HeadingRoot>
          <Grow>
            <Typography variant="h5">Ideas</Typography>
          </Grow>
          <AddIdeaButton />
        </HeadingRoot>
        <div>
          {!ideas && <Loading />}
          {ideas && ideas.map(idea => <IdeaListItem key={idea.id} {...idea} />)}
        </div>
      </PageContentMarginTop>
    </PageContentWidth>
  );
};

export { Ideas };
