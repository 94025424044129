import React, { Fragment } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Header } from "./Header";
import { useSelector } from "react-redux";
import { selectIsUserLoaded } from "../../data/redux/slices/user";
import { Loading } from "./Loading";

const Content = styled.div`
  padding: 0.5rem 0.5rem 15rem 0.5rem;
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #F2F2F2;
  }
`;

export const Layout = ({ children }) => {
  const isLoaded = useSelector(selectIsUserLoaded);
  if (!isLoaded) {
    return <Loading />;
  }
  return (
    <Fragment>
      <GlobalStyle />
      <Header />
      <Content>{children}</Content>
    </Fragment>
  );
};
