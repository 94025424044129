import React, { Fragment } from "react";
import { Loading } from "../../layout/Loading";
import { useParams } from "react-router-dom";
import { PageContent } from "../../styles/layout";
import { LinkedProjects } from "./LinkedProjects";
import { IdeaListItem } from "../IdeaListItem";
import { useIdea } from "../../../data/firebase/hooks/useIdea";
import { ItemNotFoundPage } from "../../errorPages/ItemNotFoundPage";

const Idea = () => {
  const { id } = useParams();
  const response = useIdea(id);
  if (!response) {
    return <Loading />;
  }
  if (response.error) {
    return <ItemNotFoundPage />;
  }
  const {
    data: idea,
    data: { linkedProjects }
  } = response;
  return (
    <Fragment>
      <PageContent>
        <IdeaListItem {...idea} fullPost={true} />
      </PageContent>
      <LinkedProjects ideaId={id} projects={linkedProjects} />
    </Fragment>
  );
};

export { Idea };
