import { watchIdea, getIdea } from "../clients/ideas/ideas";
import { useEffect, useState } from "react";

const cache = {};

export const useIdea = id => {
  const [idea, setIdea] = useState(cache[id]);
  useEffect(() => {
    if (id) {
      return watchIdea(id, data => {
        setIdea(data);
        cache[id] = data;
      });
    }
  }, [id]);
  return idea;
};

export const useIdeaOnce = id => {
  const [idea, setIdea] = useState(cache[id]);
  useEffect(() => {
    if (id) {
      getIdea(id).then(data => {
        setIdea(data);
        cache[id] = data;
      });
    }
  }, [id]);
  return idea;
};
