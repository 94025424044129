import React from "react";
import { ProjectListItem } from "./ProjectListItem";
import { AddProjectButton } from "./AddProjectButton";
import { Loading } from "../layout/Loading";
import { Typography } from "@material-ui/core";
import {
  PageContentWidth,
  PageContentMarginTop,
  HeadingRoot,
  Grow
} from "../styles/layout";
import { useProjects } from "../../data/firebase/hooks/useProjects";

const Projects = () => {
  const projects = useProjects();
  return (
    <PageContentWidth>
      <PageContentMarginTop>
        <HeadingRoot>
          <Grow>
            <Typography data-cy="pageTitle" variant="h5">
              Projects
            </Typography>
          </Grow>
          <AddProjectButton />
        </HeadingRoot>
        <div>
          {!projects && <Loading />}
          {projects &&
            projects.map(project => (
              <ProjectListItem key={project.id} {...project} />
            ))}
        </div>
      </PageContentMarginTop>
    </PageContentWidth>
  );
};

export { Projects };
