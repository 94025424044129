import React from "react";
import styled from "styled-components";
import { TextField, useMediaQuery, InputAdornment } from "@material-ui/core";
import { contactTypesContent } from "../../../content/contactTypes";
import { excludeProps } from "../../../utils/excludeProps";
import { mapErrorFields } from "../../../forms/validation";
import { motion } from "framer-motion";

const Root = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
`;

const ContactInput = styled(TextField)`
  && {
    margin-top: 2rem;
  }
`;

const NameField = styled(excludeProps("isTinyMobile")(ContactInput))`
  && {
    flex-grow: 0.1;
    flex-shrink: 1;

    ${({ isTinyMobile }) => (isTinyMobile ? "" : "margin-right: 2rem;")}
  }
`;

const ValueField = styled(ContactInput).attrs()`
  && {
    flex-grow: 1;
    .MuiInputBase-root {
      min-width: 15rem;
    }
  }
`;

const getValidation = selectedType => {
  const core = {
    required: "This field is required",
    maxLength: { value: 300, message: "Max length 300 characters" }
  };

  switch (selectedType) {
    case "email":
      return {
        ...core,
        validate: {
          "auth/invalid-email": value => {
            // from https://emailregex.com/
            // eslint-disable-next-line
            const isValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              value
            );
            if (!isValid) {
              return "Invalid email";
            }
            return true;
          }
        }
      };
    case "website":
      return {
        ...core,
        validate: {
          "invalid-url": value => {
            // from https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
            // eslint-disable-next-line
            const isValid = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(
              value
            );
            if (!isValid) {
              return 'Invalid web address. A correct example: "https://www.google.com"';
            }
            return true;
          }
        }
      };
    case "twitter":
      return {
        ...core,
        validate: {
          "invalid-twitter-handle": value => {
            const isValid = /^[a-zA-Z0-9_]{1,15}$/.test(value);
            if (!isValid) {
              return "Invalid twitter handle";
            }
            return true;
          }
        }
      };
    default:
      return core;
  }
};

export const ContactTextInputs = ({
  index,
  isSubmitting,
  errors,
  watch,
  registerField
}) => {
  const selectedType = watch(`contacts[${index}].type`); //TODO: Still re-renders when this value does seem to change, but performance impact negligible now
  const isTinyMobile = useMediaQuery("(max-width:400px)");
  if (!selectedType) {
    return null;
  }
  const content = contactTypesContent[selectedType];
  const namePropertyName = `${selectedType}Name`;
  const nameFieldName = `contacts[${index}].${namePropertyName}`;
  const valuePropertyName = `${selectedType}Value`;
  const valueFieldName = `contacts[${index}].${valuePropertyName}`;
  return (
    <Root
      key={selectedType}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -50, opacity: 0 }}
    >
      <NameField
        label="Label (optional)"
        helperText={`eg. "${content.example}"`}
        placeholder={`${content.defaultName} (default)`}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        name={nameFieldName}
        inputRef={registerField({
          maxLength: { value: 30, message: "Max length 30 characters" }
        })}
        fullWidth={isTinyMobile}
        disabled={isSubmitting}
        {...mapErrorFields(errors?.contacts?.[index]?.[namePropertyName])}
        {...{ isTinyMobile }}
      />
      <ValueField
        label={content.valueLabel}
        variant="outlined"
        name={valueFieldName}
        inputRef={registerField({
          ...getValidation(selectedType)
        })}
        disabled={isSubmitting}
        InputProps={{
          startAdornment:
            selectedType === "twitter" ? (
              <InputAdornment position="start">@</InputAdornment>
            ) : (
              undefined
            )
        }}
        {...mapErrorFields(errors?.contacts?.[index]?.[valuePropertyName])}
      />
    </Root>
  );
};
