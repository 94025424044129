import React from "react";
import styled from "styled-components";
import { ButtonGroup, Button, useMediaQuery } from "@material-ui/core";
import { contactTypesContent } from "../../../content/contactTypes";
import { motion } from "framer-motion";
import { Error } from "../../../forms/Error";

const Root = styled(motion.div)`
  margin-top: 2rem;
`;

const ButtonText = styled.div`
  ${({ isTinyMobile }) => (isTinyMobile ? "width: 3.5rem;" : "")}
`;

const contactTypes = Object.values(contactTypesContent);

export const ContactTypeSelect = ({
  index,
  errors,
  isSubmitting,
  registerField,
  watch,
  setValue
}) => {
  const fieldName = `contacts[${index}].type`;
  const value = watch(fieldName, "");
  const isTinyMobile = useMediaQuery("(max-width:400px)");
  return (
    <Root
      animate={{ height: "auto", opacity: 1 }}
      initial={{ height: 0, opacity: 0 }}
    >
      <ButtonGroup
        orientation={isTinyMobile ? "vertical" : "horizontal"}
        style={{ width: isTinyMobile ? "100%" : null }}
        color="secondary"
      >
        {contactTypes.map(type => {
          const isSelected = value === type.id;
          return (
            <Button
              onClick={() => setValue(fieldName, type.id, true)}
              startIcon={<type.icon />}
              variant={isSelected && "contained"}
              key={type.id}
              value={type.id}
              disabled={isSubmitting}
              data-cy={`${fieldName}-${type.id}`}
            >
              <ButtonText isTinyMobile={isTinyMobile}>
                {type.defaultName}
              </ButtonText>
            </Button>
          );
        })}
      </ButtonGroup>
      <input
        name={fieldName}
        type="hidden"
        disabled={isSubmitting}
        ref={registerField({ required: "Please select a contact type" })}
      />
      <Error error={errors?.contacts?.[index]?.type} />
    </Root>
  );
};
