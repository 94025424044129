import React, { Component } from "react";
import { connect } from "react-redux";
import {
  selectIsUserLoaded,
  selectIsLoggedIn
} from "../../data/redux/slices/user";
import { Loading } from "../layout/Loading";
import { UnauthorisedPage } from "../auth/UnauthorisedPage";

const mapState = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  userLoaded: selectIsUserLoaded(state)
});

const connectRedirectGuard = connect(mapState);

const createRedirectGuard = ({
  blockedLoggedInStatus,
  redirectTo
}) => ProtectedComponent => {
  class RedirectGuard extends Component {
    render() {
      if (
        this.props.userLoaded &&
        this.props.isLoggedIn !== blockedLoggedInStatus
      ) {
        return <ProtectedComponent {...this.props} />;
      } else if (
        this.props.userLoaded &&
        this.props.isLoggedIn === blockedLoggedInStatus
      ) {
        return <UnauthorisedPage />;
      }
      return <Loading />;
    }
  }
  return connectRedirectGuard(RedirectGuard);
};

const loggedOutOnly = createRedirectGuard({
  blockedLoggedInStatus: true,
  redirectTo: "/"
});

const loggedInOnly = createRedirectGuard({
  blockedLoggedInStatus: false,
  redirectTo: "/"
});

export { loggedOutOnly, loggedInOnly };
