import React from "react";
import { useForm } from "react-hook-form";
import { registerUser } from "../../../../data/firebase/auth/auth";
import { useDispatch } from "react-redux";
import { getError, mapErrorFields } from "../../../forms/validation";
import { Error } from "../../../forms/Error";
import { TextInput, Submit } from "../../../forms/styles";
import { checkDuplicates } from "../../../../data/firebase/clients/clients";

export const RegisterForm = () => {
  const {
    register: registerField,
    handleSubmit,
    errors,
    formState: { isSubmitting },
    setError
  } = useForm();
  const dispatch = useDispatch();
  const onSubmit = async values => {
    try {
      await registerUser(values, dispatch);
      checkDuplicates.clearCache();
    } catch (error) {
      setError([getError(error)]);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        label="Email"
        name="email"
        inputRef={registerField({
          required: "Email is required",
          validate: {
            "auth/email-already-in-use": async value => {
              const isInUse = await checkDuplicates.email(value);
              if (isInUse) {
                return "Email already in use";
              }
              return true;
            }
          }
        })}
        onBlur={e => {
          checkDuplicates.email(e.target.value);
        }}
        disabled={isSubmitting}
        {...mapErrorFields(errors.email)}
      />
      <TextInput
        label="Display Name"
        name="displayName"
        inputRef={registerField({
          required: "Display name is required",
          minLength: {
            value: 3,
            message: "Display name must be at least 3 characters"
          },
          maxLength: {
            value: 15,
            message: "Display name must be less than 15 characters"
          },
          validate: {
            "auth/display-name-allowed-characters": value => {
              if (value.length < 3) {
                return true;
              }
              const isValid = /^[a-zA-Z0-9_]*$/.test(value);
              if (!isValid) {
                return "Only letters, numbers and _ (underscore)";
              }
              return true;
            },
            "auth/display-name-already-in-use": async value => {
              const isInUse = await checkDuplicates.displayName(value);
              if (isInUse) {
                return "Display name already in use";
              }
              return true;
            }
          }
        })}
        disabled={isSubmitting}
        onBlur={e => {
          checkDuplicates.displayName(e.target.value);
        }}
        {...mapErrorFields(errors.displayName)}
      />
      <TextInput
        label="Password"
        name="password"
        type="password"
        inputRef={registerField({ required: true })}
        disabled={isSubmitting}
        {...mapErrorFields(errors.password)}
      />
      <Error error={errors.form} />
      <Submit type="submit" disabled={isSubmitting}>
        Register
      </Submit>
    </form>
  );
};
