import { contactTypesContent } from "../../components/content/contactTypes";
import uniqId from "uniqid";

const isEmpty = stringInput => {
  if (typeof stringInput !== "string") return true;

  return stringInput.trim().length < 1;
};

const getNameForInput = input => {
  if (isEmpty(input.name)) {
    return contactTypesContent[input.type].defaultName;
  }
  return input.name;
};

const mapNameAndValue = rawInput => ({
  value: rawInput[`${rawInput.type}Value`],
  name: rawInput[`${rawInput.type}Name`],
  type: rawInput.type
});

export const mapContactDetails = contactsFormValues => {
  if (!contactsFormValues) return [];

  return contactsFormValues.map(rawInput => {
    const input = mapNameAndValue(rawInput);
    return {
      id: uniqId(),
      ...input,
      name: getNameForInput(input)
    };
  });
};
