import { Button } from "@material-ui/core";
import styled from "styled-components";

export const PrimaryButton = styled(Button).attrs({
  variant: "contained",
  color: "primary"
})`
  && {
    text-transform: none;
  }
`;
