import { css } from "styled-components";

export const onePixelFor = property => css`
  ${property}: 1px;
  @media (-webkit-min-device-pixel-ratio: 1.5) {
    ${property}: 0.666px;
  }
  @media (-webkit-min-device-pixel-ratio: 2) {
    ${property}: 0.5px;
  }
  @media (-webkit-min-device-pixel-ratio: 3) {
    ${property}: 0.3333px;
  }
`;
