import React from "react";

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeLinecap="square"
    strokeMiterlimit="10"
    viewBox="0 0 100 85"
  >
    <clipPath id="a">
      <path d="M0 0h100v100H0V0z"></path>
    </clipPath>
    <g clipPath="url(#a)">
      <path
        fill="#000"
        fillOpacity="0"
        fillRule="evenodd"
        d="M0 0h100v100H0z"
      ></path>
      <path
        fill="#000"
        fillOpacity="0"
        fillRule="evenodd"
        d="M9.827 42.698C9.827 21.59 27.813 4.478 50 4.478c10.655 0 20.873 4.026 28.407 11.194 7.534 7.168 11.766 16.89 11.766 27.026 0 21.109-17.986 38.22-40.173 38.22S9.827 63.808 9.827 42.699z"
      ></path>
      <path
        stroke="red"
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeWidth="8"
        d="M9.827 42.698h0C9.827 21.59 27.813 4.478 50 4.478h0c10.655 0 20.873 4.026 28.407 11.194 7.534 7.168 11.766 16.89 11.766 27.026h0c0 21.109-17.986 38.22-40.173 38.22h0c-22.187 0-40.173-17.111-40.173-38.22z"
      ></path>
    </g>
  </svg>
);

export { Logo };
