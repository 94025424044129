import React from "react";
import styled, { css } from "styled-components";
import { renderMarkdown } from "../content/render";

const Root = styled(
  styled.div(({ theme }) => ({
    ...theme.typography.body2
  }))
)`
  && {
    ${({ theme }) => css`
      color: ${theme.palette.text.secondary};
    `}
  }
`;

export const RenderContent = ({ content }) => {
  if (!content) {
    return null;
  }
  return <Root>{renderMarkdown(content)?.tree}</Root>;
};
