import { createSlice } from "@reduxjs/toolkit";
import { userNotFound } from "./user";

const authSlice = createSlice({
  name: "auth",
  initialState: { isRegisterOpen: false, isLoginOpen: false },
  reducers: {
    setRegisterOpen(state, { payload: value = true }) {
      return { ...state, isRegisterOpen: value };
    },
    setLoginOpen(state, { payload: value = true }) {
      return { ...state, isLoginOpen: value };
    },
    switchAuth(state) {
      return {
        isRegisterOpen: !state.isRegisterOpen,
        isLoginOpen: !state.isLoginOpen
      };
    },
    unauthorisedAction(state) {
      return { ...state, isRegisterOpen: true };
    }
  },
  extraReducers: {
    [userNotFound]: () => {
      return { isRegisterOpen: false };
    }
  }
});

export const selectIsRegisterOpen = state => !!state.auth.isRegisterOpen;
export const selectIsLoginOpen = state => !!state.auth.isLoginOpen;

const { actions, reducer: auth } = authSlice;

export const {
  setRegisterOpen,
  setLoginOpen,
  switchAuth,
  unauthorisedAction
} = actions;

export { auth };
