import React from "react";
import { PageContentWidth } from "../styles/layout";
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Button
} from "@material-ui/core";
import { setRegisterOpen, setLoginOpen } from "../../data/redux/slices/auth";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const HeadingRoot = styled.div`
  padding: 2rem 0 1rem 2rem;
  display: flex;
`;

const Content = styled(CardContent)`
  && {
    padding: 2rem;
  }
`;

const Actions = styled(CardActions)`
  && {
    padding: 0 2rem 2rem 2rem;
  }
`;

export const UnauthorisedPage = () => {
  const dispatch = useDispatch();
  return (
    <PageContentWidth data-cy="unauthorisedPage">
      <HeadingRoot>
        <Typography variant="h5">Unauthorised</Typography>
      </HeadingRoot>
      <Card>
        <Content>
          <Typography variant="h6">
            You're not currently authorised to view this page. Please log in or
            register:
          </Typography>
        </Content>
        <Actions>
          <Button
            onClick={() => dispatch(setRegisterOpen(true))}
            variant="contained"
            color="primary"
          >
            Register
          </Button>
          <Button
            onClick={() => dispatch(setLoginOpen(true))}
            variant="contained"
            color="secondary"
          >
            Login
          </Button>
        </Actions>
      </Card>
    </PageContentWidth>
  );
};
