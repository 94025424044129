import firebase from "@firebase/app";
import { ideaRef } from "../ideas/ideas";
import { mapContactDetails } from "../../../mapping/contactDetails";

export const projectsCollection = () =>
  firebase.firestore().collection("projects");
export const projectRef = id => projectsCollection().doc(id);

export const postProject = async ({ title, description, contacts }, idea) => {
  const newProject = {
    title,
    description,
    likes: 0,
    createdBy: firebase.auth().currentUser.uid,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    changedAt: firebase.firestore.FieldValue.serverTimestamp(),
    linkedIdeas: {},
    linkedIdeaIds: [],
    contacts: mapContactDetails(contacts)
  };
  if (idea) {
    newProject.linkedIdeas[idea.id] = {
      id: idea.id,
      title: idea.title,
      description: idea.description
    };
    newProject.linkedIdeaIds[0] = idea.id;
  }
  const projectPost = projectsCollection().add(newProject);
  const newProjectRef = await projectPost;
  if (idea) {
    const relatedProject = { id: newProjectRef.id, title, description };
    const ideaUpdate = ideaRef(idea.id).update({
      [`linkedProjects.${relatedProject.id}`]: relatedProject,
      linkedProjectIds: firebase.firestore.FieldValue.arrayUnion(
        relatedProject.id
      )
    });
    await ideaUpdate;
  }
  return newProjectRef.id;
};

export const getProject = async id => {
  const ref = projectRef(id);
  const snap = await ref.get();
  return {
    ...snap.data(),
    id
  };
};

export const watchProject = (id, set) => {
  const ref = projectRef(id);
  return ref.onSnapshot({ includeMetadataChanges: true }, doc => {
    if (!doc.exists) {
      return set({ error: "item-not-found" });
    }
    const mappedResults = {
      id: doc.id,
      ...doc.data(),
      pending: doc.metadata.hasPendingWrites
    };
    set({
      data: mappedResults
    });
  });
};

export const getProjects = async () => {
  const ref = projectsCollection();
  const snap = await ref.get();
  return snap.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

export const watchProjects = set => {
  const ref = projectsCollection().orderBy("likes", "desc");
  return ref.onSnapshot({ includeMetadataChanges: true }, snap => {
    const mappedResults = snap.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      pending: doc.metadata.hasPendingWrites
    }));
    set(mappedResults);
  });
};
