import React from "react";
import { AppBar, Typography, useMediaQuery } from "@material-ui/core";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { UserActions } from "./userActions/UserActions";
import { AppBarButton } from "./userActions/styles";
import { Logo } from "./Logo";
import { NavLinks } from "./NavLinks";

const Root = styled(AppBar).attrs({ position: "static", elevation: 1 })`
  && {
    background-color: white;
    padding: 0 2rem;

    ${props => props.theme.breakpoints.down("sm")} {
      padding: 0 1rem;
    }
    ${props => props.theme.breakpoints.down("xs")} {
      padding: 0 0.5rem;
    }
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Grow = styled.div`
  flex-grow: 1;
`;

const LogoContainer = styled.span`
  && {
    height: 1.8rem;
    width: 1.8rem;
  }
`;

const LogoText = styled(Typography).attrs({ variant: "h5" })`
  && {
    font-weight: 450;
    border-bottom: solid 3px #ff0000;
  }
`;

export const Header = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  return (
    <Root>
      <HeaderRow>
        <AppBarButton data-cy="appLogo" component={Link} to="/">
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <LogoText>Projects Hub</LogoText>
        </AppBarButton>
        {!isMobile && <NavLinks />}
        {isMobile && <Grow />}
        <UserActions />
      </HeaderRow>
      {isMobile && <NavLinks />}
    </Root>
  );
};
