import React from "react";
import { Add } from "@material-ui/icons";
import { PrimaryButton } from "../styles/buttons";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectIsLoggedIn } from "../../data/redux/slices/user";
import { unauthorisedAction } from "../../data/redux/slices/auth";

export const AddProjectButton = () => {
  let actionProps = { component: Link, to: "/projects/new-project" };
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  if (!isLoggedIn) {
    actionProps = { onClick: () => dispatch(unauthorisedAction()) };
  }

  return (
    <PrimaryButton {...actionProps} endIcon={<Add />} data-cy="newProject">
      New Project
    </PrimaryButton>
  );
};
