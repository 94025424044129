import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Ideas } from "./components/ideas/Ideas";
import { Idea } from "./components/ideas/ideaPage/Idea";
import { Layout } from "./components/layout/Layout";
import { Provider } from "react-redux";
import { createStore } from "./data/redux/store";
import { useAuth } from "./data/firebase/auth/useAuth";
import { NewIdea } from "./components/ideas/newIdea/NewIdea";
import { loggedInOnly } from "./components/hocs/loggedInGuards";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import { theme } from "./themes/theme";
import { Projects } from "./components/projects/Projects";
import { NewProject } from "./components/projects/newProject/NewProject";
import { Project } from "./components/projects/Project";
import { NotFoundPage } from "./components/errorPages/NotFoundPage";
import { withErrorBoundary } from "./components/ErrorBoundary";

const AppUtilities = () => {
  if (module && module.hot) {
    const btn = window.top.document.querySelector(".reporter .restart");
    if (btn) {
      btn.click();
    }
  }

  useAuth();
  return null;
};

function App() {
  return (
    <Provider store={createStore()}>
      <MuiThemeProvider theme={theme}>
        <StyledComponentsThemeProvider theme={theme}>
          <AppUtilities />
          <Router>
            <Layout>
              <Switch>
                <Route
                  path="/"
                  exact={true}
                  component={withErrorBoundary(Ideas)}
                />
                <Route
                  path="/projects"
                  exact={true}
                  component={withErrorBoundary(Projects)}
                />
                <Route
                  path="/ideas/new-idea"
                  exact={true}
                  component={withErrorBoundary(loggedInOnly(NewIdea))}
                />
                <Route
                  path="/projects/new-project"
                  exact={true}
                  component={withErrorBoundary(loggedInOnly(NewProject))}
                />
                <Route
                  path="/ideas/:id"
                  exact={true}
                  component={withErrorBoundary(Idea)}
                />
                <Route
                  path="/ideas/:ideaId/projects/new"
                  exact={true}
                  component={withErrorBoundary(loggedInOnly(NewProject))}
                />
                <Route
                  path="/projects/:id"
                  exact={true}
                  component={withErrorBoundary(Project)}
                />
                <Route component={NotFoundPage} />
              </Switch>
            </Layout>
          </Router>
        </StyledComponentsThemeProvider>
      </MuiThemeProvider>
    </Provider>
  );
}

export { App };
